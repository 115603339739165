import styled, { css } from "styled-components";
import { Button as GrommetButton } from "grommet";
import { darken } from "polished";
import theme from "../../config/theme";
import { Route } from "./Text";

const jumboVariantCss = css`
  ${props =>
    props.variant === "jumbo" &&
    `
    padding: 8px 28px;
    font-size: 20px;
    
    @media (min-width: ${theme.breakpoints.large}) {
      padding: 12px 36px;
      font-size: 28px;
      
      &:hover,
      &:focus {
        box-shadow: inset 0 -1.75em 0 0 ${darken(0.4, theme.colors.brand)};
      }
    }
    
    &:hover,
    &:focus {
      box-shadow: inset 0 -2em 0 0 ${darken(0.4, theme.colors.brand)};
    }
  `};
`;

const smallVariantCss = css`
  ${props => props.variant === "small" && "padding: 4px 22px; font-size: 14px;"};
`;

const dark1VariantCss = css`
  ${props =>
    props.color === "dark1" &&
    `
    color: ${theme.colors.brand};
    background-color: ${theme.colors.dark1};
    border-color: ${theme.colors.dark1};
        
    &:disabled {
      color: ${theme.colors.brand} !important;
    }

    &:hover,
    &:focus {
      color: ${theme.colors.dark1};
      box-shadow: inset 0 -2.5em 0 0 ${theme.colors.brand};
    }
  `};
`;

export default styled(GrommetButton)`
  transition: 0.25s;
  ${smallVariantCss};

  &:disabled {
    color: ${theme.colors.dark1} !important;
    box-shadow: none !important;
  }

  &:hover,
  &:focus {
    box-shadow: inset 0 -2.35em 0 0 ${darken(0.4, theme.colors.brand)};
    color: ${theme.colors.brand};
  }

  ${jumboVariantCss}

  ${dark1VariantCss}

  ${props =>
    props.variant === "outlined" &&
    `
    color: ${theme.colors.brand};
    border: 1px solid ${theme.colors.brand};
    
    &:disabled {
      color: ${theme.colors.brand} !important;
    }
    
    &.active,
    &:hover,
    &:focus {
      color: ${theme.colors.dark1};
      box-shadow: inset 0 -2.5em 0 0 ${theme.colors.brand};
    }
  `};
`;

export const LinkButton = styled(Route)`
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  font: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin: 0;
  background: transparent;
  overflow: visible;
  text-transform: none;
  border: 1px solid #00fff0;
  border-radius: 0;
  color: #000000;
  padding: 9px 40px;
  font-size: 18px;
  line-height: 32px;
  background: #00fff0;
  color: #000000;
  border-radius: 0;
  transition: 0.25s;
  font-weight: 500;
  line-height: 24px;
  font-family: "Bebas Neue", "BlinkMacSystemFont", "-apple-system", Arial, sans-serif;

  &:hover,
  &:focus {
    box-shadow: inset 0 -2.35em 0 0 #003330;
    color: #00fff0;
  }

  ${jumboVariantCss}
  ${smallVariantCss}
  ${dark1VariantCss}
`;
