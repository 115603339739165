import React, { Component, createRef } from "react";
import styled from "styled-components";
import { TextArea, TextInput } from "grommet";
import theme from "../../config/theme";
import Button from "../elements/Button";
import { Categories } from "../data/posts";
import { CloseIcon } from "../styles/icons";
import Container from "../core/Container";

const StyledModal = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 20;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);

  .inner-body {
    position: relative;
    margin: 48px 0 80px;
    width: 90%;
    max-width: 550px;
    z-index: 12;
    background: ${theme.colors.body};

    .modal-content {
      min-height: 600px;
      color: ${theme.colors.background};
      padding: 30px 20px 30px;
      box-shadow: 0 14px 34px ${theme.colors.dark1};
    }

    .close {
      position: absolute;
      z-index: 10;
      right: 10px;
      top: 10px;
      padding: 10px;

      &:hover,
      &:focus {
        background-color: ${theme.colors.dark1};
        box-shadow: none;

        .icon {
          filter: invert(100%);
        }
      }

      .icon {
        display: block;
        width: 12px;
        height: 12px;
        filter: invert(10%) sepia(460%) contrast(80%) saturate(40%) hue-rotate(150deg);
      }
    }
  }

  .title {
    font-size: 28px;
    line-height: 1.5;
    margin-bottom: 4px;
    text-align: center;
    font-family: ${theme.font.secondary};
  }

  p {
    max-width: 460px;
    text-align: center;
    font-size: 14px;
    margin: 0 auto;
  }

  .modal-form {
    max-width: 450px;
    margin: 20px auto 0;

    .input-group {
      margin-bottom: 15px;
      min-height: 68px;
    }

    label {
      font-size: 14px;
      font-weight: bold;
    }

    input,
    textarea,
    select {
      color: ${theme.colors.dark1};
      font-size: 14px;
      font-family: ${theme.font.primary};

      &::placeholder {
        color: ${theme.colors.dark2};
      }

      &:focus {
        border-width: 0 0 2px 0;
        border-color: ${theme.colors.inputBorder};
      }
    }

    input,
    select {
      min-height: 37px;
      padding: 0;
    }

    select {
      display: block;
      width: 100%;
      min-height: 38px;
      background: transparent;
      border-width: 0 0 1px 0;
      color: ${theme.colors.dark1};
      border-color: ${theme.colors.inputBorder};

      &:focus {
        outline: 0;
      }
    }

    textarea {
      min-width: 100%;
      max-width: 100%;
      min-height: 100px;
      padding-left: 0;
      padding-right: 0;
      border-width: 0 0 1px 0;
    }

    button {
      margin: 10px auto 0 auto;
      display: block;
      height: 44px;
      font-size: 18px;
      color: ${theme.colors.title};

      &:disabled {
        color: ${theme.colors.title} !important;
        border-color: ${theme.colors.dark1} !important;
      }

      &:hover,
      &:focus {
        color: ${theme.colors.background};
        border-color: ${theme.colors.brand};
      }
    }
  }

  @media (min-width: ${theme.breakpoints.large}) {
    padding: 55px 30px;

    .inner-body {
      .modal-content {
        padding: 30px 20px;
      }
    }

    .title {
      font-size: 32px;
    }

    p {
      font-size: 16px;
      line-height: 1.2;
    }

    .modal-form {
      margin: 40px auto 0;

      input,
      textarea,
      select {
        font-size: 16px;
      }

      button {
        height: 50px;
        font-size: 30px;
      }
    }
  }
`;

export default class RequestPricingModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: false,
      loading: false
    };

    this.body = createRef();
    this.formRef = createRef();

    const container = Container.getInstance();
    this.apiClient = container.getApiClient();
    this.messagesService = container.getMessagesService();

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.isOpened === this.state.isOpened) {
      return;
    }

    if (this.state.isOpened) {
      document.body.classList.add("modal-up");
    } else {
      document.body.classList.remove("modal-up");
    }
  }

  onClickOutside(e) {
    if (this.state.loading) {
      return;
    }

    if (this.body.current && this.body.current.contains(e.target)) {
      return;
    }

    this.setState({ isOpened: false });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { name, email, category, desc } = e.target.elements;

    this.setState({ loading: true });

    this.apiClient
      .post("messages/request-pricing", {
        name: name.value,
        email: email.value,
        category: category.value,
        message: desc.value
      })
      .then(response => {
        this.messagesService.addSuccess(response.message);
        this.formRef.current.reset();
      })
      .catch(err => {
        this.messagesService.addError(err.message);
      })
      .finally(() => {
        this.setState({ loading: false, isOpened: false });
      });
  }

  close() {
    this.setState({ isOpened: false });
  }

  open() {
    this.setState({ isOpened: true });
  }

  render() {
    const { isOpened, loading } = this.state;

    if (!isOpened) {
      return <></>;
    }

    return (
      <StyledModal onClick={this.onClickOutside}>
        <div className="inner-body" ref={this.body}>
          <Button
            className="close"
            variant="plain"
            onClick={this.close}
            label={<CloseIcon className="icon" />}
            disabled={loading}
          />
          <div className="modal-content">
            <h3 className="title">Request pricing</h3>
            <p>Feel free to request a non-binding price calculation for the realization of your project by us.</p>
            <form className="modal-form" ref={this.formRef} onSubmit={this.handleSubmit}>
              <div className="input-group">
                <label htmlFor="email">Email</label>
                <TextInput
                  name="email"
                  className="form-control"
                  placeholder="Your email"
                  required
                  type="email"
                  disabled={loading}
                />
              </div>
              <div className="input-group">
                <label htmlFor="name">Name</label>
                <TextInput
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder="Your full name"
                  required
                  type="text"
                  disabled={loading}
                />
              </div>
              <div className="input-group">
                <label htmlFor="category">Category</label>
                <select id="category" name="category" className="form-control" required disabled={loading}>
                  {Object.values(Categories)
                    .filter(c => c !== Categories.ALL)
                    .map(c => (
                      <option key={c} value={c}>
                        {c}
                      </option>
                    ))}
                  <option key="other" value="other">
                    Other
                  </option>
                </select>
              </div>
              <div className="input-group">
                <label htmlFor="desc">Description</label>
                <TextArea
                  id="desc"
                  name="desc"
                  className="form-control"
                  placeholder="Try to describe your project as a task"
                  required
                  disabled={loading}
                />
              </div>
              <Button type="submit" label="Send" primary color="dark1" disabled={loading} />
            </form>
          </div>
        </div>
      </StyledModal>
    );
  }
}
