import React, { useEffect, useState } from "react";
import { Box } from "grommet";
import { rgba } from "polished";
import styled from "styled-components";
import Logo from "../elements/Logo";
import { NavLink, Route } from "../elements/Text";
import { ABOUT_PAGE, CONTACT_PAGE, HOME_PAGE, PROJECTS_PAGE, SERVICES_PAGE } from "../../config/routes";
import theme from "../../config/theme";
import Button from "../elements/Button";
import DIContainer from "../core/Container";

const Wrapper = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  position: sticky;
  top: 0;
  z-index: 10;
  ${props =>
    props.transparent
      ? `background-color: ${rgba(theme.colors.background, 0.6)};`
      : `background-color: ${theme.colors.background};`}
  transition: background-color 0.25s linear;

  &.undocked {
    ${props => props.transparent && `background-color: ${rgba(theme.colors.background, 1)};`}
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  }

  .hamburger {
    display: flex;
    align-items: center;
    justify-content: center;

    .hamburger-box {
      width: 30px;
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: ${theme.colors.body} !important;
      width: 30px;
    }

    &:hover,
    &:focus {
      outline: 0;
      opacity: 0.7;
    }
  }

  .request-pricing {
    display: none;
  }

  @media (min-width: ${theme.breakpoints.large}) {
    .request-pricing {
      display: block;
    }

    .hamburger {
      display: none;
    }
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: ${theme.containerSizing.xlarge};
  padding: 0 15px;
`;

const Nav = styled.nav`
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0.8;

  @media (min-width: ${theme.breakpoints.large}) {
    display: flex;
  }
`;

const Header = ({ sideMenuOpen, setMenuOpen, openRequestPricingModal }) => {
  const THRESHOLD = 60;
  const container = DIContainer.getInstance();
  const router = container.getRouter();
  const [undocked, setUndocked] = useState(false);
  const [transparent, setTransparent] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > THRESHOLD) {
      setUndocked(true);
    } else {
      setUndocked(false);
    }
  };

  const handleResize = () => {
    setMenuOpen(current => {
      if (current !== false) {
        return false;
      }
    });
  };

  const onRouteChange = () => {
    setMenuOpen(false);
    setTransparent(router.getCurrentRouteName() === HOME_PAGE);
  };

  useEffect(() => {
    if (sideMenuOpen) {
      setUndocked(true);
    } else {
      setUndocked(window.scrollY > THRESHOLD);
    }
  }, [sideMenuOpen]);

  useEffect(() => {
    setUndocked(window.scrollY > THRESHOLD);
    setTransparent(router.getCurrentRouteName() === HOME_PAGE);

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    router.subscribe(onRouteChange);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
      router.unsubscribe(onRouteChange);
    };
  }, []);

  return (
    <Wrapper className={`${undocked ? "undocked" : ""}`} transparent={transparent}>
      <Container>
        <Box direction="row" justify="between" basis="full">
          <Route route={HOME_PAGE}>
            <Logo />
          </Route>

          <Nav>
            <NavLink route={HOME_PAGE}>Home</NavLink>
            <NavLink route={ABOUT_PAGE}>About</NavLink>
            <NavLink route={SERVICES_PAGE}>Services</NavLink>
            <NavLink route={PROJECTS_PAGE} exact={false}>
              Projects
            </NavLink>
            <NavLink route={CONTACT_PAGE}>Contact</NavLink>
          </Nav>
          <Button
            className="request-pricing"
            label="Request Pricing"
            primary
            color="brand"
            onClick={openRequestPricingModal}
          />

          <button
            className={`hamburger hamburger--minus${sideMenuOpen ? " is-active" : ""}`}
            type="button"
            data-toggle="collapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setMenuOpen(open => !open)}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </Box>
      </Container>
    </Wrapper>
  );
};

export default Header;
