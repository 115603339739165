export default class UpdateManager {
  callbacks = [];

  subscribe(callback) {
    this.callbacks.push(callback);
  }

  unsubscribe(callback) {
    this.callbacks = this.callbacks.filter(existing => existing !== callback);
  }

  notify() {
    for (const callback of this.callbacks) {
      callback();
    }
  }

  onPageLoad(callback) {
    if (document.readyState === "complete") {
      callback();
    } else {
      window.addEventListener("load", callback);
    }
  }
}
