import React from "react";
import styled from "styled-components";
import { Box, Text } from "grommet";
import config from "../../config/website";
import logo from "../images/logo/Logo_TITAN_h_inverse.svg";
import { Route, Title } from "../elements/Text";
import { Container } from "../elements/Layout";
import { ABOUT_PAGE, CONTACT_PAGE, HOME_PAGE, PROJECTS_PAGE, SERVICES_PAGE } from "../../config/routes";
import theme from "../../config/theme";

const StyledFooter = styled.footer`
  background-color: #1d1d2f;
  padding-top: 60px;

  .content-wrapper {
    align-items: center;
  }

  a {
    font-size: 12px;
    color: ${theme.colors.white};
    font-weight: 500;

    &:hover {
      text-decoration: none;
      color: ${theme.colors.brand};
    }
  }

  @media (min-width: ${theme.breakpoints.medium}) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    .content-wrapper {
      align-items: start;
    }
  }
`;

const Footer = () => (
  <StyledFooter>
    <Container>
      <Box width="full" align="center">
        <Box className="content-wrapper" width="large" direction="row-responsive" gap="large">
          <Box basis="1/4">
            <Box width="200px">
              <Title level="6" size="24px" color="title" margin={{ bottom: "medium" }}>
                Navigation
              </Title>
              <Route route={HOME_PAGE}>Home</Route>
              <Route route={ABOUT_PAGE}>About</Route>
              <Route route={SERVICES_PAGE}>Services</Route>
              <Route route={PROJECTS_PAGE}>Projects</Route>
              <Route route={CONTACT_PAGE}>Contact</Route>
            </Box>
          </Box>

          <Box basis="1/4">
            <Box width="200px">
              <Title level="6" size="24px" color="title" margin={{ bottom: "medium" }}>
                Contact us
              </Title>
              <a href="mail&#116;o&#58;i&#37;&#54;E&#37;66%&#54;F%40tita&#110;&#37;64e&#37;73ig&#37;6&#69;&#46;eu">
                info&#64;&#116;itandesign&#46;e&#117;
              </a>
              <a href={`tel:${config.bio.phone}`}>{config.bio.phone}</a>
            </Box>
          </Box>

          <Box basis="1/4">
            <Box width="200px">
              <Title level="6" size="24px" color="title" margin={{ bottom: "medium" }}>
                Find us
              </Title>
              <Text size="12px" color="white" style={{ lineHeight: "inherit" }} margin={{ bottom: "6px" }} weight={500}>
                {config.bio.address.name}
              </Text>
              <Text size="12px" color="white" margin={{ bottom: "6px" }} weight={500}>
                {config.bio.address.street}
              </Text>
              <Text size="12px" color="white" margin={{ bottom: "6px" }} weight={500}>
                {`${config.bio.address.postal} ${config.bio.address.city}`}
              </Text>
              <Text size="12px" as="address" color="white" weight={500}>
                {config.bio.address.country}
              </Text>
            </Box>
          </Box>

          <Box basis="1/4" align="center">
            <Box width="200px" align="center">
              <Route route={HOME_PAGE}>
                <img src={logo} style={{ width: "100%", height: "auto" }} alt={config.bio.company} />
              </Route>
            </Box>
          </Box>
        </Box>

        <Box pad={{ vertical: "medium" }} direction="row" justify="center" align="center">
          <Text size="12px" color="white">
            &copy; {new Date().getFullYear()} {config.bio.company} - All rights reserved
          </Text>
        </Box>
      </Box>
    </Container>
  </StyledFooter>
);

export default Footer;
