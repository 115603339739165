export default class Router {
  _routes = [];

  _current = null;

  observers = [];

  constructor(routes) {
    this._routes = routes;
  }

  getCurrentRouteName() {
    return this._current;
  }

  init(location = "") {
    const loc = this.trimSlashes(location);
    const route = this._routes.find(r => this.trimSlashes(r.route) === loc);

    if (route) {
      this._current = route.name;
    }

    this.notify();
  }

  getRoute(name) {
    return this._routes.find(r => r.name === name);
  }

  trimSlashes(url) {
    return url.replace(/^\/+|\/+$/g, "");
  }

  subscribe(callback) {
    this.observers.push(callback);
  }

  unsubscribe(callback) {
    this.observers = this.observers.filter(o => o !== callback);
  }

  notify() {
    this.observers.forEach(o => o());
  }
}
