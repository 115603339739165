import websites from "../images/services/web_design.jpeg";
import webapps from "../images/services/web_apps.jpeg";
import ecommerce from "../images/services/ecommerce.jpeg";
import crm from "../images/services/crm.jpeg";
import mobile from "../images/services/mobile_apps.jpeg";
import cm from "../images/projects/cm/cryptomood.jpg";
import cmActive from "../images/projects/cm/cm_active.jpg";
import mk from "../images/projects/mk/muscleking.jpg";
import mkBanner from "../images/projects/mk/banner.jpg";
import euromiko from "../images/projects/euromiko/euromiko.jpg";
import euromikoBanner from "../images/projects/euromiko/banner.jpg";
import tradingacademy from "../images/projects/ta/tradingacademy.jpg";
import tradingacademyBanner from "../images/projects/ta/banner.jpg";
import divanti from "../images/projects/divanti/divanti.jpg";
import divantiBanner from "../images/projects/divanti/banner.jpg";
import akademie from "../images/projects/akademie/akademie.jpg";
import akadmieBanner from "../images/projects/akademie/banner.jpg";
import cmGallery1 from "../images/projects/cm/cryptomood - business.jpg";
import cmGallery2 from "../images/projects/cm/cryptomood - icons.jpg";
import cmGallery3 from "../images/projects/cm/cryptomood - preview.jpg";
import cmGallery4 from "../images/projects/cm/cryptomood - pricing.jpg";
import cmGallery5 from "../images/projects/cm/cryptomood - widgets.jpg";
import akademieGallery1 from "../images/projects/akademie/akademie1.jpg";
import akademieGallery2 from "../images/projects/akademie/akademie2.jpg";
import akademieGallery3 from "../images/projects/akademie/akademie3.jpg";
import akademieGallery4 from "../images/projects/akademie/akademie4.jpg";
import taGallery1 from "../images/projects/ta/crm1.jpg";
import taGallery2 from "../images/projects/ta/crm2.jpg";
import divanti1 from "../images/projects/divanti/divanti1.jpg";
import divanti2 from "../images/projects/divanti/divanti2.jpg";
import divanti3 from "../images/projects/divanti/divanti3.jpg";
import divanti4 from "../images/projects/divanti/divanti4.jpg";
import euromiko1 from "../images/projects/euromiko/euromiko1.jpg";
import euromiko2 from "../images/projects/euromiko/euromiko2.jpg";
import euromiko3 from "../images/projects/euromiko/euromiko3.jpg";
import euromiko4 from "../images/projects/euromiko/euromiko4.jpg";
import muscleking1 from "../images/projects/mk/muscleking1.jpg";
import muscleking2 from "../images/projects/mk/muscleking2.jpg";
import muscleking3 from "../images/projects/mk/muscleking3.jpg";
import muscleking4 from "../images/projects/mk/muscleking4.jpg";
import roniqThumb from "../images/projects/roniq/thumb.jpg";
import roniqBanner from "../images/projects/roniq/banner.jpg";
import roniqGallery1 from "../images/projects/roniq/1.jpg";
import roniqGallery2 from "../images/projects/roniq/2.jpg";
import roniqGallery3 from "../images/projects/roniq/3.jpg";
import roniqGallery4 from "../images/projects/roniq/4.jpg";
import jvluThumb from "../images/projects/jvlu/thumb.jpg";
import jvluBanner from "../images/projects/jvlu/banner.jpg";
import jvluGallery1 from "../images/projects/jvlu/1.jpg";
import jvluGallery2 from "../images/projects/jvlu/2.jpg";
import jvluGallery3 from "../images/projects/jvlu/3.jpg";
import {
  AKADEMIE_PROJECT,
  CRYPTOMOOD_PROJECT,
  DIVANTI_PROJECT,
  EUROMIKO_PROJECT,
  MUSCLEKING_PROJECT,
  RONIQ_STUDIO,
  JVLU_PROJECT,
  TRADINGACADEMY_PROJECT
} from "../../config/routes";

export const Categories = {
  ALL: "All",
  WEBSITES: "Websites",
  CRM_SYSTEMS: "CRM Systems",
  MOBILE_APPS: "Mobile apps",
  WEB_APPS: "Web apps",
  ECOMMERCE: "E-Commerce"
};

export const services = [
  {
    key: "websites",
    title: "Websites",
    content:
      "Creating modern, functional websites which are fully responsible for every type of device. SEO optimization is a must-have.",
    image: websites
  },
  {
    key: "webapps",
    title: "Web apps",
    content:
      "Need a custom solution for your business? Not a problem. We will create for you a project made to measure with a guarantee of satisfaction.",
    image: webapps
  },
  {
    key: "ecommerce",
    title: "E-commerce",
    content:
      "Enter the online world with a fast and modern e-shop. We will prepare a robust website ready to sell your goods or services to your customers.",
    image: ecommerce
  },
  {
    key: "crm",
    title: "CRM Systems",
    content:
      "One perfect application to keep an eye on your projects, sales performance, customer service, recruiting, marketing, and even more.",
    image: crm
  },
  {
    key: "apps",
    title: "Mobile apps",
    content:
      "Do you want to create a mobile application? We are ready to develop a product from an idea through a prototype to a perfect app.",
    image: mobile
  }
];

export const projects = [
  {
    title: "JVLU Slovakia",
    excerpt:
      "JVLU Slovakia is a transportation company specializing in international road transport and warehousing. A simple and fast one-pager to suit all the client’s needs. The part of the project was also creating a logo.",
    featuredImage: jvluBanner,
    thumbnail: jvluThumb,
    tags: ["Company", "Transportation"],
    categories: [Categories.WEBSITES],
    route: JVLU_PROJECT,
    date: "2021",
    url: "http://jvluslovakia.eu",
    gallery: [jvluGallery1, jvluGallery2, jvluGallery3]
  },
  {
    title: "Roniq studio",
    excerpt:
      "Roniq is a brow and lash studio in Brno. Very minimalistic, light, and modern website contains all the necessary information such as price list, gallery of works, and location. CTA button is directing to a reservation system.",
    featuredImage: roniqBanner,
    thumbnail: roniqThumb,
    tags: ["Company", "Brows", "Lashes", "Beauty"],
    categories: [Categories.WEBSITES],
    route: RONIQ_STUDIO,
    date: "2021",
    url: "http://roniqstudio.cz",
    gallery: [roniqGallery1, roniqGallery2, roniqGallery3, roniqGallery4]
  },
  {
    title: "Euromiko",
    excerpt:
      "Euromiko is a driving school situated in Trenčín. The main aim of this project was to redesign the old web and bring some adjustments to the backend. A clean and simple, new website is now more interactive and faster due to significant amends in the codebase.",
    featuredImage: euromikoBanner,
    thumbnail: euromiko,
    tags: ["Company", "Driving school"],
    categories: [Categories.WEBSITES],
    route: EUROMIKO_PROJECT,
    date: "2021",
    url: "http://euromiko.com",
    gallery: [euromiko1, euromiko2, euromiko3, euromiko4]
  },
  {
    title: "Akademie Obchodování",
    excerpt:
      "Akademie obchodování is a company founded by professional traders with the aim of educating people about forex trading. Website is accessible to users with valid code. Section “Client’s zone” is linking to the user’s account containing information about one’s trades, withdrawals, etc.",
    featuredImage: akadmieBanner,
    thumbnail: akademie,
    tags: ["Forex trading"],
    categories: [Categories.WEBSITES],
    route: AKADEMIE_PROJECT,
    date: "2018",
    url: "http://akademieobchodovani.cz",
    gallery: [akademieGallery1, akademieGallery2, akademieGallery3, akademieGallery4]
  },
  {
    title: "Trading Academy",
    excerpt:
      "CRM system for Trading academy provides broker a whole overview over his clients, their trading accounts, allowing traders management, processing deposits, margin call settings etc. User-friendly design makes application easy to use not only for experienced traders, but also for newbies.",
    featuredImage: tradingacademyBanner,
    thumbnail: tradingacademy,
    tags: ["CRM", "Forex trading", "Client management"],
    categories: [Categories.CRM_SYSTEMS, Categories.WEB_APPS],
    route: TRADINGACADEMY_PROJECT,
    date: "2018",
    url: "http://tr.tradingacademy.club",
    gallery: [taGallery1, taGallery2]
  },
  {
    title: "CryptoMood",
    excerpt:
      "CryptoMood is a small startup developing sentiment analysis tools for cryptotrading. The aim of the project was to create a web page with basic information about the company’s products and solutions. The website contains a business version with a different design for B2B clients.",
    featuredImage: cmActive,
    thumbnail: cm,
    tags: ["Product", "Cryptocurrencies"],
    categories: [Categories.WEBSITES],
    route: CRYPTOMOOD_PROJECT,
    date: "2020",
    url: "http://cryptomood.com",
    gallery: [cmGallery1, cmGallery2, cmGallery3, cmGallery4, cmGallery5]
  },
  {
    title: "Muscleking",
    excerpt:
      "Muscleking is an e-commerce offering a great number of products for sport and nutrition. Items are easy to look up thanks to different kinds of sorting. The product detail page contains product variations and special offers. The part of the website is also a blog.\n",
    featuredImage: mkBanner,
    thumbnail: mk,
    tags: ["E-shop", "Brand", "Nutrition", "Blog"],
    categories: [Categories.ECOMMERCE],
    route: MUSCLEKING_PROJECT,
    date: "2019",
    url: "http://muscleking.eu",
    gallery: [muscleking1, muscleking2, muscleking3, muscleking4]
  },
  {
    title: "Divanti",
    excerpt:
      "Divanti is Czech company that creates modern and quality interiors. The website is running as an e-shop providing a wide variety of furniture for the customers. E-commerce solution is based on WordPress making editing information and uploading new products easy and fast. ",
    featuredImage: divantiBanner,
    thumbnail: divanti,
    tags: ["E-shop", "Furniture"],
    categories: [Categories.ECOMMERCE],
    route: DIVANTI_PROJECT,
    date: "2020",
    url: "http://divanti.cz",
    gallery: [divanti1, divanti2, divanti3, divanti4]
  }
];
