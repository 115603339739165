// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-akademie-obchodovani-jsx": () => import("./../../../src/pages/projects/akademie-obchodovani.jsx" /* webpackChunkName: "component---src-pages-projects-akademie-obchodovani-jsx" */),
  "component---src-pages-projects-cryptomood-jsx": () => import("./../../../src/pages/projects/cryptomood.jsx" /* webpackChunkName: "component---src-pages-projects-cryptomood-jsx" */),
  "component---src-pages-projects-divanti-jsx": () => import("./../../../src/pages/projects/divanti.jsx" /* webpackChunkName: "component---src-pages-projects-divanti-jsx" */),
  "component---src-pages-projects-euromiko-jsx": () => import("./../../../src/pages/projects/euromiko.jsx" /* webpackChunkName: "component---src-pages-projects-euromiko-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-projects-jvlu-jsx": () => import("./../../../src/pages/projects/jvlu.jsx" /* webpackChunkName: "component---src-pages-projects-jvlu-jsx" */),
  "component---src-pages-projects-muscleking-jsx": () => import("./../../../src/pages/projects/muscleking.jsx" /* webpackChunkName: "component---src-pages-projects-muscleking-jsx" */),
  "component---src-pages-projects-roniq-jsx": () => import("./../../../src/pages/projects/roniq.jsx" /* webpackChunkName: "component---src-pages-projects-roniq-jsx" */),
  "component---src-pages-projects-trading-academy-jsx": () => import("./../../../src/pages/projects/trading-academy.jsx" /* webpackChunkName: "component---src-pages-projects-trading-academy-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */)
}

