export default class JsonApiClient {
  url = null;

  constructor(url) {
    this.url = url;
  }

  async post(endpoint, data) {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      request.open("POST", `${this.url}/${endpoint}`, true);

      request.setRequestHeader("Content-Type", "application/json");
      request.withCredentials = true;

      request.onload = function() {
        const response = JSON.parse(request.response);

        if (this.status >= 200 && this.status < 300) {
          resolve(response);
        } else {
          const error = new Error(response.message);
          error.code = this.status;
          reject(error);
        }
      };

      request.onerror = function() {
        const response = JSON.parse(request.response);
        const error = new Error(response.message);
        error.code = this.status;
        reject(error);
      };

      request.send(JSON.stringify(data));
    });
  }
}
