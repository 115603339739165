import styled from "styled-components";
import pin from "../images/icons/pin.svg";
import envelope from "../images/icons/envelope.svg";
import phone from "../images/icons/phone.svg";
import close from "../images/icons/close.svg";

export const PinIcon = styled.i`
  background: url(${pin}) center no-repeat;
  background-size: cover;
  width: 21px;
  height: 21px;
`;

export const EnvelopeIcon = styled.i`
  background: url(${envelope}) center no-repeat;
  background-size: cover;
  width: 21px;
  height: 21px;
`;

export const PhoneIcon = styled.i`
  background: url(${phone}) center no-repeat;
  background-size: cover;
  width: 21px;
  height: 21px;
`;

export const CloseIcon = styled.i`
  background: url(${close}) center no-repeat;
  background-size: cover;
  width: 21px;
  height: 21px;
`;
