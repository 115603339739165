import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { SnackbarProvider, withSnackbar } from "notistack";
import Container from "../core/Container";
import { CloseIcon } from "../styles/icons";

const MessagesRenderer = withSnackbar(props => {
  const messagesService = useRef(null);

  useEffect(() => {
    const container = Container.getInstance();
    messagesService.current = container.getMessagesService();
    messagesService.current.getUpdateManager().subscribe(onMessagesChanged);

    return () => {
      messagesService.current.getUpdateManager().unsubscribe(onMessagesChanged);
    };
  }, []);

  function onMessagesChanged() {
    const { enqueueSnackbar } = props;

    if (messagesService.current === null) {
      return;
    }

    messagesService.current.getActiveMessages().forEach(message => {
      enqueueSnackbar(message.text, { variant: message.type });
    });
  }

  return null;
});

const Close = styled.span`
  cursor: pointer;
  padding: 6px;
  position: absolute;
  right: 0;
  top: 6px;

  .icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    filter: invert(1);
  }
`;

const Snackbar = () => {
  const notistackRef = useRef(null);

  function onClickDismiss(key) {
    if (notistackRef.current === null) {
      return;
    }

    notistackRef.current.closeSnackbar(key);
  }

  return (
    <SnackbarProvider
      classes={{
        variantSuccess: "alert alert-success",
        variantError: "alert alert-error",
        variantWarning: "alert alert-warning",
        variantInfo: "alert alert-info"
      }}
      ref={notistackRef}
      hideIconVariant
      maxSnack={5}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      action={key => (
        <Close onClick={() => onClickDismiss(key)}>
          <CloseIcon className="icon" />
        </Close>
      )}
    >
      <MessagesRenderer />
    </SnackbarProvider>
  );
};

export default Snackbar;
