module.exports = {
  font: {
    primary: '"Montserrat", "BlinkMacSystemFont", "-apple-system", Arial, sans-serif',
    secondary: '"Bebas Neue", "BlinkMacSystemFont", "-apple-system", Arial, sans-serif'
  },
  colors: {
    title: "#F3D7EE",
    control: "#D8B9C3",
    background: "#212136",
    placeholder: "#D8B9C3",
    body: "#D8B9C3",
    brand: "#00FFF0",
    focus: "#00FFF0",
    black: "#000000",
    white: "#FFFFFF",
    navlink: "#D8B9C3",
    light: "#D8B9C3",
    light1: "#C4C4C4",
    accent: ["#77BA99", "#D33F49", "#81FCED", "#FFCA58"],
    neutral: ["#613D7F", "#796A8D", "#40205A", "#A2423D"],
    dark1: "#1C1E3A",
    dark2: "#3F4159",
    inputBorder: "#796A8D",
    status: {
      critical: "#DB4F4F",
      error: "#E94C48",
      warning: "#FFCA58",
      ok: "#00CC57",
      unknown: "#CCCCCC",
      disabled: "#CCCCCC"
    },
    text: {
      dark: "#D8B9C3",
      light: "#444444"
    },
    border: {
      dark: "#796A8D",
      light: "#ced4da"
    }
  },
  breakpoints: {
    small: "576px",
    medium: "768px",
    large: "992px",
    xlarge: "1310px"
  },
  containerSizing: {
    small: "540px",
    medium: "720px",
    large: "960px",
    xlarge: "1280px"
  },
  boxShadows: {
    small: "0 4px 25px 5px rgba(0, 0, 0, 0.25)",
    medium: "0 4px 25px 5px rgba(0, 0, 0, 0.6)"
  }
};
