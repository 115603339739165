import styled from "styled-components";
import theme from "../../config/theme";

export const Container = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: ${theme.breakpoints.small}) {
    max-width: ${theme.containerSizing.small};
  }

  @media (min-width: ${theme.breakpoints.medium}) {
    max-width: ${theme.containerSizing.medium};
  }

  @media (min-width: ${theme.breakpoints.large}) {
    max-width: ${theme.containerSizing.large};
  }

  @media (min-width: ${theme.breakpoints.xlarge}) {
    max-width: ${theme.containerSizing.xlarge};
  }
`;

export const Section = styled.section`
  display: block;
  align-items: center;
  ${props => (!props.offsetBottom ? "" : "padding-bottom: 80px;")}
  ${props => (!props.offsetTop ? "" : "padding-top: 80px;")}

  p {
    margin-top: 30px;
  }

  @media (min-width: ${theme.breakpoints.large}) {
    ${props => (!props.offsetBottom ? "" : "padding-bottom: 150px;")}
    ${props => (!props.offsetTop ? "" : "padding-top: 150px;")}

    p {
      margin-top: 0;
      max-width: 550px;
    }
  }
`;
