import { createGlobalStyle } from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import theme from "../../config/theme";

const SlickStyle = createGlobalStyle`
  .slick-slider {
    .slick-prev {
       left: 10px;
    }
    
    .slick-next {
       right: 10px;
    }
    
    .slick-prev:before, .slick-next:before {
       color: ${theme.colors.body};
    }
      
    .slick-slide {
       outline: 0 !important;
    }
    
    .slick-dots li button:before {
       opacity: 1;
       font-size: 12px;
       color: ${theme.colors.light1};
    }
    
    .slick-dots li.slick-active button:before {
       color: ${theme.colors.brand};
    }
  }
  
  .slick-prev, .slick-next {
    z-index: 5;
  }
  
  @media (min-width: ${theme.breakpoints.large}) {
    .slick-slider {
      .slick-prev {
         left: -10px;
      }
      
      .slick-next {
         right: -10px;
      }
    }
  }
`;

export default SlickStyle;
