import { createGlobalStyle } from "styled-components";
import { breakpoints, colors, font } from "../../config/theme";
import "./font.css";

const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  html {
    margin: 0;
    padding: 0;
    height: 100%;
    text-rendering: optimizeLegibility;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  body {
    position: relative;
    min-height: 100%;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-size: 16px;
    font-family: ${font.primary};
    background-color: ${colors.background};
    
    &.menu-open {
      overflow: hidden;
    }
    
    &.modal-up {
      overflow: hidden;
    }
  }
  
  @media (min-width: ${breakpoints.medium}) {
    body {
      padding-bottom: 298px;    
    }
  }
    
  a {
    color: ${colors.navlink};
    font-weight: 500;
    text-decoration: none;
  }
  
    
  div, span, h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  
  ol, ul {
    list-style: none;
  }
  
  .alert {
    padding: 4px 18px 4px 12px !important;
    font-size: 14px !important;
    font-family: ${font.primary} !important;
    border-radius: 0 !important;
    letter-spacing: 0 !important;
    
    &.alert-success {
      background-color: ${colors.status.ok} !important;
    }
    
    &.alert-error {
      background-color: ${colors.status.error} !important;
    }
  }
`;

export default GlobalStyle;
