module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"
  siteTitle: "Titan Design", // Navigation and Site Title
  siteTitleAlt: "Titan Design", // Alternative Site title for SEO
  siteTitleShort: "Titan Design", // short_name for manifest
  siteHeadline: "Creating cutting edge web experience", // Headline for schema.org JSONLD
  siteUrl: "https://titandesign.eu", // Domain of your site. No trailing slash!
  siteLanguage: "en", // Language Tag on <html> element
  siteLogo: "/logo.png", // Used for SEO and manifest
  siteDescription: "Titan Design is a creative group delivering a cutting edge web experience",
  author: "xpacman", // Author for schema.org JSONLD
  bio: {
    phone: "+421 908 883 366",
    email: "info@titandesign.eu",
    company: "Titan Design",
    obfuscatedEmail:
      "&#109;a&#105;&#108;to&#58;i%6E%66o&#64;ti&#116;%61n&#100;&#101;&#115;i%6&#55;n&#46;&#101;%&#55;&#53;'>i&#110;fo&#64;t&#105;tand&#101;s&#105;gn&#46;&#101;u",
    address: {
      name: "TitanTech s.r.o.",
      vat: "05540208",
      street: "Potocká 58/7, Kohoutovice",
      postal: "623 00",
      city: "Brno",
      country: "Czech republic"
    }
  },
  ogSiteName: "Titan Design", // Facebook Site Name
  ogLanguage: "en_US", // Facebook Language
  googleAnalyticsID: "G-RWY0VG5MB1"
};
