import React from "react";
import { Grommet } from "grommet";
import GlobalStyle from "./src/styles/global";
import "hamburgers/dist/hamburgers.css";
import SlickStyle from "./src/styles/slick";
import theme from "./config/grommet";
import Layout from "./src/components/Layout";
import Container from "./src/core/Container";
import Snackbar from "./src/components/Snackbar";

const container = Container.getInstance();
const router = container.getRouter();

export const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>;

export const onRouteUpdate = ({ location }) => {
  router.init(location.pathname);
};

export const wrapRootElement = ({ element }) => (
  <Grommet theme={theme}>
    <GlobalStyle />
    <SlickStyle />
    <Snackbar />
    {element}
  </Grommet>
);
