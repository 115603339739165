import React from "react";
import styled from "styled-components";
import logo from "../images/logo/logo-ns.svg";
import config from "../../config/website";

const StyledLogo = styled.strong`
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${logo});
  width: 125px;
  height: 44px;
`;

const Logo = () => <StyledLogo>{config.siteTitleShort}</StyledLogo>;

export default Logo;
