import React, { useEffect } from "react";
import styled from "styled-components";
import theme from "../../config/theme";
import { NavLink } from "../elements/Text";
import { ABOUT_PAGE, CONTACT_PAGE, HOME_PAGE, PROJECTS_PAGE, SERVICES_PAGE } from "../../config/routes";

const SIDEBAR_WIDTH = 300;

const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 8;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Sidebar = styled.div`
  display: block;
  visibility: visible;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 80px;
  width: ${SIDEBAR_WIDTH}px;
  margin-right: -${SIDEBAR_WIDTH}px;
  background-color: ${theme.colors.background};
  z-index: 10;
  box-shadow: -10px 10px 10px rgb(0 0 0 / 30%);
  transition: margin-right 0.25s ease-in-out;

  &.open {
    margin-right: 0;
  }

  .nav-list {
    margin-bottom: 15px;

    a {
      display: block;
      width: 100%;
      padding: 10px 40px;
      text-align: left;
      border-bottom: 1px solid ${theme.colors.dark2};
    }
  }

  .request-pricing {
    font-weight: 500;
  }

  @media (min-width: ${theme.breakpoints.large}) {
    display: none;
    visibility: hidden;
  }
`;

const SideNavigation = ({ open, setMenuOpen, openRequestPricingModal }) => {
  useEffect(() => {
    if (open) {
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }
  }, [open]);

  return (
    <>
      <Sidebar className={open ? "open" : ""}>
        <div className="nav-list">
          <NavLink route={HOME_PAGE}>Home</NavLink>
          <NavLink route={ABOUT_PAGE}>About</NavLink>
          <NavLink route={SERVICES_PAGE}>Services</NavLink>
          <NavLink route={PROJECTS_PAGE} exact={false}>
            Projects
          </NavLink>
          <NavLink route={CONTACT_PAGE}>Contact</NavLink>
          <NavLink
            className="request-pricing"
            onClick={e => {
              e.preventDefault();
              openRequestPricingModal();
            }}
            route={CONTACT_PAGE}
          >
            Request Pricing
          </NavLink>
        </div>
      </Sidebar>
      {open && <Overlay onClick={() => setMenuOpen(false)} />}
    </>
  );
};

export default SideNavigation;
