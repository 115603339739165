import UpdateManager from "./UpdateManager";

export default class MessagesService {
  updateManager = new UpdateManager();

  activeMessages = [];

  addMessages(messages) {
    this.activeMessages = messages;
    this.updateManager.notify();
  }

  getActiveMessages() {
    return this.activeMessages;
  }

  addMessage(message) {
    this.addMessages([message]);
  }

  addSuccess(message) {
    this.addMessages([{ text: message, type: "success" }]);
  }

  addInfo(message) {
    this.addMessages([{ text: message, type: "info" }]);
  }

  addWarning(message) {
    this.addMessages([{ text: message, type: "warning" }]);
  }

  addError(message) {
    this.addMessages([{ text: message, type: "error" }]);
  }

  /**
   * @returns {UpdateManager}
   */
  getUpdateManager() {
    return this.updateManager;
  }
}
