import Router from "./Router";
import { routes } from "../../config/routes";
import JsonApiClient from "./JsonApiClient";
import MessagesService from "./MessagesService";
import UpdateManager from "./UpdateManager";

export default class Container {
  static instance = null;

  router = null;

  apiClient = null;

  messagesService = null;

  pageUpdateManager = new UpdateManager();

  /**
   * @returns Container
   */
  static getInstance() {
    if (this.instance === null) {
      this.instance = new this();
    }
    return this.instance;
  }

  /**
   * @returns Router
   */
  getRouter() {
    if (this.router === null) {
      this.router = new Router(routes);
    }
    return this.router;
  }

  /**
   * @returns JsonApiClient
   */
  getApiClient() {
    if (this.apiClient === null) {
      this.apiClient = new JsonApiClient(process.env.API_URL);
    }
    return this.apiClient;
  }

  /**
   * @returns MessagesService
   */
  getMessagesService() {
    if (this.messagesService === null) {
      this.messagesService = new MessagesService();
    }
    return this.messagesService;
  }

  /**
   * @returns UpdateManager
   */
  getPageUpdateManager() {
    return this.pageUpdateManager;
  }
}
