export const HOME_PAGE = "home";
export const SERVICES_PAGE = "services";
export const PROJECTS_PAGE = "projects";
export const ABOUT_PAGE = "about";
export const CONTACT_PAGE = "contact";
export const EUROMIKO_PROJECT = "euromiko";
export const CRYPTOMOOD_PROJECT = "cryptomood";
export const MUSCLEKING_PROJECT = "muscleking";
export const AKADEMIE_PROJECT = "akademie";
export const TRADINGACADEMY_PROJECT = "tradingacademy";
export const DIVANTI_PROJECT = "divanti";
export const RONIQ_STUDIO = "roniq";
export const JVLU_PROJECT = "jvlu";

export const routes = [
  { name: HOME_PAGE, route: "/" },
  { name: SERVICES_PAGE, route: "/services" },
  { name: PROJECTS_PAGE, route: "/projects" },
  { name: ABOUT_PAGE, route: "/about" },
  { name: CONTACT_PAGE, route: "/contact" },
  { name: EUROMIKO_PROJECT, route: "/projects/euromiko" },
  { name: CRYPTOMOOD_PROJECT, route: "/projects/cryptomood" },
  { name: MUSCLEKING_PROJECT, route: "/projects/muscleking" },
  { name: AKADEMIE_PROJECT, route: "/projects/akademie-obchodovani" },
  { name: TRADINGACADEMY_PROJECT, route: "/projects/trading-academy" },
  { name: DIVANTI_PROJECT, route: "/projects/divanti" },
  { name: RONIQ_STUDIO, route: "/projects/roniq" },
  { name: JVLU_PROJECT, route: "/projects/jvlu" }
];
