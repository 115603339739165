import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Heading } from "grommet";
import { Link } from "gatsby";
import theme from "../../config/theme";
import Container from "../core/Container";

export const Title = ({ children, decorator, weight, size, ...props }) => {
  const H = styled(Heading)`
    ${props => props.color === undefined && `color: ${theme.colors.title};`}
    letter-spacing: 1px;
    line-height: 1;
    ${() => size === undefined && "font-size: 1.75rem;"}
    font-weight: ${weight || 400};
    position: relative;

    ${decorator &&
      `
      padding-left: 15px;
      
      &::before {
      content: " ";
      background-color: ${theme.colors.brand};
      width: 1px;
      height: 21px;
      display: block;
      position: absolute;
      top: 2px;
      left: 0;
    }`}
     
    @media (min-width: ${theme.breakpoints.medium}) {
    ${() => (size === undefined ? "font-size: 44px;" : `font-size: ${size};`)}
              
      &::before {
        top: 3px;
        height: 32px;
      }
    }
  `;

  return <H {...props}>{children}</H>;
};

Title.propTypes = {
  decorator: PropTypes.bool,
  ...Heading.propTypes,
  weight: PropTypes.number
};

const StyledLink = styled(Link)`
  font-size: 18px;
  padding: 0 6px;
  letter-spacing: 0;
  width: 94px;
  text-align: center;
  transition: all 0.3s cubic-bezier(0, 0.55, 0.45, 1);

  &.current,
  &:hover,
  &:focus {
    color: ${theme.colors.brand};
    letter-spacing: 1px;
  }
`;

export const NavLink = ({ route, exact, ...props }) => {
  const container = Container.getInstance();
  const router = container.getRouter();
  const target = router.getRoute(route).route;

  const [active, setActive] = useState(isActive());

  useEffect(() => {
    router.subscribe(onRouteChange);

    return () => {
      router.unsubscribe(onRouteChange);
    };
  }, []);

  function onRouteChange() {
    setActive(isActive());
  }

  function isActive() {
    const currentRoute = router.getCurrentRouteName();

    if (!exact) {
      const _current = router.getRoute(currentRoute);

      if (_current) {
        return _current.route.includes(target);
      }
    }

    return currentRoute === route;
  }

  return <StyledLink className={active ? "current" : ""} to={target} {...props} />;
};

NavLink.propTypes = {
  route: PropTypes.string,
  exact: PropTypes.bool
};

NavLink.defaultProps = {
  exact: true
};

export const Route = ({ route, hash, ...props }) => {
  const container = Container.getInstance();
  const router = container.getRouter();

  return <Link to={`${router.getRoute(route).route}${hash}`} {...props} />;
};

Route.propTypes = {
  route: PropTypes.string,
  hash: PropTypes.string
};

Route.defaultProps = {
  hash: ""
};
