import React, { createRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import Footer from "./Footer";
import SideNavigation from "./SideNavigation";
import DIContainer from "../core/Container";
import RequestPricingModal from "./RequestPricingModal";

const Layout = ({ children, location }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const container = DIContainer.getInstance();
  const router = container.getRouter();
  const requestPricingModal = createRef();

  useEffect(() => {
    router.init(location.pathname);
  }, [location]);

  function openRequestPricingModal() {
    requestPricingModal.current.open();

    document.activeElement.blur();
  }

  return (
    <>
      <Header sideMenuOpen={menuOpen} setMenuOpen={setMenuOpen} openRequestPricingModal={openRequestPricingModal} />
      <SideNavigation open={menuOpen} setMenuOpen={setMenuOpen} openRequestPricingModal={openRequestPricingModal} />
      <RequestPricingModal ref={requestPricingModal} />
      <main>{children}</main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
};

export default Layout;
