import { dark, generate } from "grommet/themes";
import { deepMerge } from "grommet/utils";

const { colors, font, breakpoints, boxShadows } = require("./theme");

// Scale factor used for margin/padding and other sizing
const baseSpacing = 28;

// Custom theme overrides
const customized = {
  global: {
    font: {
      family: font.primary,
      size: "14px"
    },
    colors,
    input: {
      weight: "400"
    },
    hover: {
      background: {
        dark: {
          color: "accent-2",
          opacity: 1
        },
        light: {
          color: "accent-2",
          opacity: 1
        }
      },
      color: {
        dark: "white",
        light: "white"
      }
    },
    borderSize: {
      xsmall: "1px",
      small: "2px",
      medium: `${baseSpacing / 6}px`,
      large: `${baseSpacing / 2}px`,
      xlarge: `${baseSpacing}px`
    },
    control: {
      border: {
        width: "1px",
        radius: "0"
      },
      disabled: {
        // opacity: OpacityType
      }
    },
    edgeSize: {
      medium: "20px",
      large: "40px",
      xlarge: "78px"
    },
    size: {
      // xxsmall: baseSpacing * 2 + "px",
      // xsmall: baseSpacing * 4 + "px",
      // small: baseSpacing * 8 + "px",
      // medium: baseSpacing * 16 + "px",
      // large: "992px"
      xlarge: "1180px"
      // xxlarge: baseSpacing * 64 + "px",
      // full: '100%'
    },
    breakpoints: {
      small: {
        value: breakpoints.medium.replace("px", "")
      },
      medium: {
        value: breakpoints.large.replace("px", "")
      }
    },
    elevation: {
      light: {
        // medium: "0 0 0 black"
      },
      dark: boxShadows
    }
  },
  button: {
    color: "black",
    border: {
      width: "1px",
      color: { dark: "transparent", light: "transparent" },
      radius: "0"
    },
    padding: {
      horizontal: "40px",
      vertical: "9px"
    },
    extend: {
      fontWeight: "500",
      lineHeight: "24px",
      fontFamily: font.secondary
    }
  },
  anchor: {
    fontWeight: 500
  },
  radioButton: {
    extend: {
      padding: "2px"
    }
  },
  textArea: {
    extend: {
      padding: ".375rem .75rem",
      lineHeight: "1.5",
      fontSize: "0.8rem",
      boxShadow: "none",
      transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out"
    }
  },
  textInput: {
    extend: {
      borderWidth: "0 0 1px 0",
      color: colors.control,
      padding: ".375rem 0",
      lineHeight: "1.5",
      fontSize: "0.8rem",
      boxShadow: "none",
      transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out"
    }
  },
  text: {
    xsmall: {
      size: "10px"
    },
    small: {
      size: "14px",
      height: "24px"
    },
    medium: {
      size: "18px",
      height: "32px"
    },
    large: {
      size: "22px",
      height: "32px"
    }
  },
  layer: {
    background: "transparent"
  },
  checkBox: {
    check: {
      extend: {
        background: "gray"
      }
    },
    color: "white",
    toggle: {
      color: { dark: "white", light: "white" },
      knob: {
        extend: {
          width: "12px",
          top: 0,
          left: 0,
          height: "12px"
        }
      },
      background: "accent-1",
      size: "46px"
    }
  },
  heading: {
    extend: {
      lineHeight: "1.2",
      marginTop: "0"
    },
    font: {
      family: font.secondary
    },
    weight: "400",
    level: {
      1: {
        font: {
          weight: 600
        }
      },
      2: {
        medium: {
          size: "44px"
        }
      },
      3: {
        medium: {
          size: "1.75rem"
        }
      },
      4: {
        medium: {
          size: "1.5rem"
        },
        large: {
          size: "22px"
        }
      },
      5: {
        medium: {
          size: "1.25rem"
        }
      },
      6: {
        medium: {
          size: "1rem"
        }
      }
    }
  },
  paragraph: {
    extend: {
      fontWeight: "400"
    },
    xsmall: {
      size: "10px",
      maxWidth: null
    },
    small: {
      size: "14px",
      height: "24px",
      maxWidth: null
    },
    medium: {
      size: "18px",
      height: "32px",
      maxWidth: null
    },
    large: {
      size: "22px",
      height: "32px",
      maxWidth: null
    }
  }
};

export default deepMerge(generate(baseSpacing), dark, customized);
